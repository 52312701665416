import React from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { GatsbyImage } from "gatsby-plugin-image"

const ProductLoopMessageWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 30px 20px 10px;
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
`

const DesktopSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  position: relative;
  background-size: contain;

  @media (max-width: 600px) {
    display: none;
  }
`

const MobileSection = styled.div`
  flex-direction: column;
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
`

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    position: relative;
    top: 0%;
    left: 0%;
    transform: translate(0%, 0%);
    padding: 20px;
    align-items: inherit;
  }
`

const ProductLoopMessageTitle = styled.h2`
  text-transform: uppercase;
  color: #213d5b;
  font-size: 18px;
  line-height: 1.3;
  font-family: "Gotham Book";
  font-weight: bold;
  margin: 0px;
  text-align: center;
  position: relative;
  margin-bottom: 25px;

  @media (max-width: 850px) {
    font-size: 15px;
    text-align: left;
  }

  @media (max-width: 600px) {
    text-align: left;
    margin-bottom: 25px;
  }
`

const ProductLoopMessageSubTitle = styled(Link)`
  background-color: #262626;
  font-size: 16px;
  font-family: "Gotham";
  font-weight: bold;
  padding: 7px 15px;
  border: 1px solid #262626;
  text-align: center;
  border-radius: 5px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 210px;
  height: 40px;

  :hover {
    background: #fff;
    border: 1px solid #262626;
    color: #262626;
  }

  @media (max-width: 600px) {
    font-size: 14px;
    max-width: 295px;
    width: 100%;
    align-self: center;
  }
`

const Image = styled(GatsbyImage)`
  margin: 0px;
  width: 100%;
  min-height: 200px;
  object-fit: cover;
  border-radius: 6px;

  @media (max-width: 600px) {
    object-fit: contain;
    min-height: auto;
  }
`

export const ProductLoopMessage = ({
  intl,
  text,
  buttonText,
  desktopImage,
  mobileImage,
}) => (
  <ProductLoopMessageWrapper>
    <Container>
      <DesktopSection>
        {desktopImage && desktopImage.localFile.extension === "svg" ? (
          <Image
            className="lazyload"
            data-src={
              desktopImage
                ? desktopImage.data.url
                : "https://static.percko.com/uploads/baaae4c7-b059-485a-90e5-175f87917f5f.svg"
            }
            alt=""
          />
        ) : (
          <Image
            objectFit="contain"
            image={desktopImage.localFile.childImageSharp.gatsbyImageData}
            alt=""
          />
        )}
        <Content>
          {text && (
            <ProductLoopMessageTitle
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}

          {buttonText && (
            <ProductLoopMessageSubTitle to="/videoloop/">
              {buttonText}
            </ProductLoopMessageSubTitle>
          )}
        </Content>
      </DesktopSection>

      <MobileSection>
        {mobileImage && mobileImage.localFile.extension === "svg" ? (
          <Image
            className="lazyload"
            data-src={
              mobileImage
                ? mobileImage.data.url
                : "https://static.percko.com/uploads/baaae4c7-b059-485a-90e5-175f87917f5f.svg"
            }
            alt=""
          />
        ) : (
          <Image
            objectFit="contain"
            image={mobileImage.localFile.childImageSharp.gatsbyImageData}
            alt=""
          />
        )}
        <Content>
          {text && (
            <ProductLoopMessageTitle
              dangerouslySetInnerHTML={{ __html: text }}
            />
          )}

          {buttonText && (
            <ProductLoopMessageSubTitle to="/videoloop/">
              {buttonText}
            </ProductLoopMessageSubTitle>
          )}
        </Content>
      </MobileSection>
    </Container>
  </ProductLoopMessageWrapper>
)

export default injectIntl(ProductLoopMessage)
