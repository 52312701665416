import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import BlankStar from "../../../../static/blank_star.png"
import GreenStar from "../../../../static/green_star.png"
import HalfStar from "../../../../static/half_star.png"
import TrustpilotStar45 from "../../../../static/TrustpilotStar-4.5.svg"

import {
  MobilePrice,
  ProductPriceWrapper,
  ProductPrice,
  ProductPlusDetail,
} from "../styled"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}

const ProductTopSectionWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    align-items: flex-start;
  }
`

const LeftSection = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
`

const RightSection = styled.div`
  position: relative;
  display: none;

  @media (max-width: 850px) {
    display: block;
  }
`

const ProductSubSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

const ProductTitleImage = styled.div`
  max-width: 135px;
  margin-right: 10px;
  margin-bottom: 5px;

  @media (max-width: 600px) {
    max-width: 100px;
    margin-bottom: 0px;
  }

  @media (max-width: 440px) {
    max-width: 70px;
    margin-right: 5px;
  }

  > img {
    margin: 0px;
    max-height: 40px;

    @media (max-width: 600px) {
      max-height: 24px;
    }
  }
`

const ProductReviewRating = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: "Gotham HTF Book";
  margin-bottom: 5px;

  @media (max-width: 600px) {
    font-size: 13px;
    margin-bottom: 0px;
  }

  @media (max-width: 440px) {
    font-size: 11px;
  }
`

const StarCount = styled.div`
  font-size: 14px;
  font-family: "Gotham HTF Book";

  @media (max-width: 600px) {
    font-size: 13px;
  }

  @media (max-width: 440px) {
    font-size: 11px;
  }
`

const TotalStar = styled.div`
  font-size: 14px;
  font-family: "Gotham HTF Book";

  @media (max-width: 600px) {
    font-size: 13px;
  }

  @media (max-width: 440px) {
    font-size: 11px;
  }
`

const ReviewStar = styled.div`
  display: flex;
  flex-direction: row;
  font-family: "Gotham HTF Book";
  margin: 0px;
  position: relative;
  cursor: pointer;
`

const Star = styled.span`
  font-size: 14px;
  padding: 0 2px;
  color: #e0e700;
  color: #f2b01e;

  @media (max-width: 440px) {
    font-size: 11px;
  }
`

const TotalReviewCount = styled.div`
  cursor: pointer;
  text-decoration: underline;
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
  margin: 0px 1px;
  max-width: 23px;
`

const Text = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-family: "Gotham";
  font-weight: bold;
  font-style: normal;

  @media (max-width: 480px) {
    font-size: 13px;
  }
`

const TrustPilotStarImage = styled.img`
  width: 100%;
  max-width: 130px;
  margin: 0px;
`

export const WaviiTopSection = ({
  intl,
  image,
  review_data,
  price,
  openModal,
  alma,
}) => {
  const totalStars = data => {
    let totalStar = 0
    let avgReview = 0
    for (let i = 0; i < data.length; i++) {
      totalStar = totalStar + Number(data[i].review.stars)
    }
    avgReview = (totalStar / data.length).toFixed(1)
    return avgReview
  }

  const stars = data => {
    let stars = []
    let blank_stars = 5 - data
    for (let i = 1; i <= data; i++) {
      stars.push(
        <Image
          key={"fill" + i.toString()}
          className="lazyload"
          data-src={GreenStar}
          alt=""
        />
      )
    }
    if (blank_stars >= 1) {
      for (let i = 0; i < blank_stars; i++) {
        stars.push(
          <Image
            key={"blank" + i.toString()}
            className="lazyload"
            data-src={BlankStar}
            alt=""
          />
        )
      }
    } else {
      for (let i = 0; i < blank_stars; i++) {
        stars.push(
          <Image
            key={"blank" + i.toString()}
            className="lazyload"
            data-src={HalfStar}
            alt=""
          />
        )
      }
    }
    return stars
  }

  const handleScrolling = () => {
    $(document).ready(function() {
      $("html, body").animate(
        {
          scrollTop: $("#review_section").offset().top - 170,
        },
        0
      )
    })
  }
  return (
    <ProductTopSectionWrapper>
      <LeftSection>
        <ProductSubSection>
          {image && (
            <ProductTitleImage>
              <img className="lazyload" data-src={image.data.url} />
            </ProductTitleImage>
          )}

          <ProductReviewRating>
            <ReviewStar onClick={() => handleScrolling()}>
              {/* {stars(totalStars(review_data))} */}
              <TrustPilotStarImage
                className="lazyload"
                data-src={TrustpilotStar45}
              />
            </ReviewStar>
          </ProductReviewRating>
        </ProductSubSection>
      </LeftSection>

      <RightSection>
        <MobilePrice alma={alma}>
          <ProductPriceWrapper
            style={{
              flexDirection: "column",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <ProductPrice style={{ minWidth: "auto" }}>
              {process.env.GATSBY_CURRENCY_TYPE !== "eur"
                ? process.env.GATSBY_CURRENCY
                : ""}
              {price}
              {process.env.GATSBY_CURRENCY_TYPE === "eur"
                ? process.env.GATSBY_CURRENCY
                : ""}
            </ProductPrice>
            <ProductPlusDetail onClick={() => openModal(true)}>
              {intl.formatMessage({ id: "product_plus_details_text" })}
            </ProductPlusDetail>
          </ProductPriceWrapper>
        </MobilePrice>
      </RightSection>
    </ProductTopSectionWrapper>
  )
}

export default injectIntl(WaviiTopSection)
