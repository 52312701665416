import React, { Fragment } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"

const ProductWaviiBannerWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 8px 20px;
  background: ${({ page }) => (page == "loop" ? "#213D5B" : "#fd7c70")};
  background: ${({ background }) => (background ? background : "#5db9bc")};

  @media (max-width: 600px) {
    // display: none;
    // background: transparent;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
`

const BannerText = styled.div`
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: "Gotham";
  font-weight: bold;
  font-style: normal;

  @media (max-width: 600px) {
    color: #262626;
    color: #fff;
  }

  ul {
    display: flex;
    margin: 0px;
    list-style: none;
    align-items: center;
    justify-content: space-evenly;
    margin: 0px auto;

    @media (max-width: 600px) {
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-evenly;
    }
  }

  li {
    margin: 0px;
    font-family: "Gotham";
    list-style-type: none;
    font-weight: bold;
  }

  li:nth-of-type(even) {
    // list-style-type: disc;
    // margin-left: 25px;
  }
`

export const ProductWaviiBanner = ({ intl, data, page, background }) => (
  <ProductWaviiBannerWrapper page={page} background={background}>
    {data && (
      <Container>
        {process.env.GATSBY_ACTIVE_ENV === "gbp" ? (
          <BannerText>
            <ul>
              <li>
                <strong>Free worldwide delivery from the UK</strong>
              </li>
              <li>
                100-day <strong>money-back guarantee</strong>
              </li>
            </ul>
          </BannerText>
        ) : (
          <BannerText dangerouslySetInnerHTML={{ __html: data }} />
        )}
      </Container>
    )}
  </ProductWaviiBannerWrapper>
)

export default injectIntl(ProductWaviiBanner)
