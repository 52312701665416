import React, { Fragment, useState, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import {
  ProductTitleWrapper,
  ProductSingleTitle,
  ProductInfoSection,
  Container,
  ProductTopDesktopSection,
  ProductInfoBox,
  ProductImageSection,
  ProductMobileBelowImageSection,
  ProductRightInfoSection,
  ProductSummaryTop,
  ProductInfo,
  ProductInfoBottom,
  DesktopPrice,
  ProductPriceWrapper,
  ProductPrice,
  ProductPlusDetail,
  ProductInfoBottomRight,
  ProductAddToCart,
  ProductEstimateShippingBox,
  ProductEstimateShippingIcon,
  ProductEstimateShippingText,
} from "../../../components/Products/styled"

import WaviiTopSection from "../../../components/Products/WaviiTopSection"
import ProductImageSlider from "../../../components/Products/ProductImageSlider"
import ProductImageVideoSlider from "../../../components/Products/ProductImageVideoSlider"
import ProductMaterialInfo from "../../../components/Products/ProductMaterialInfo"
import ProductWaviiMobileBanner from "../../../components/Products/ProductWaviiMobileBanner"
import ProductQuantity from "../../../components/Products/ProductQuantity"
import ProductWaviiBanner from "../../../components/Products/ProductWaviiBanner"
import ProductWaviiInfo from "../../../components/Products/ProductWaviiInfo"
import ProductWhyChooseSection from "../../../components/Products/ProductWhyChooseSection"
import ProductTechnology from "../../../components/Products/ProductTechnology"
import ProductLoopMessage from "../../../components/Products/ProductLoopMessage"
import ProductReviewSection from "../../../components/Products/ProductReviewSection"
import WaviiFaqSection from "../../../components/Products/WaviiFaqSection"
import CustomerService from "../../../components/Layout/CustomerService"
import Footer from "../../../components/Layout/Footer"
import Testimonials from "../../../components/Layout/Testimonials"
import { withStoreContext } from "../../../context/StoreContext"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}
import { navigate } from "@reach/router"
import PriceDetails from "../../../components/Products/PriceDetails"
import BottomStickyAddToCart from "../../../components/Products/BottomStickyAddToCart"
import { imageSliderloop, imageSliderloopEn } from "../../../utils/loopHelper"
import SeoComp from "../../../components/SeoComp"
import {
  addToCartScript,
  productGtmEvent,
} from "../../../utils/additionalScriptHelpers"
import { cartEstimateDeliveryText } from "../../../utils/cartHelpers"
import { navigateToCart } from "../../../utils/navigateToCart"
import TrustPilotReviewSection from "../../../components/Products/TrustPilotReviewSection"

const Loop = ({
  intl,
  data,
  data: { shopifyProduct: product },
  storeContext,
  storeContext: { checkout, addVariantToCart },
}) => {
  const [openModal, setopenModal] = useState(false)
  const [selectedVariant, setselectedVariant] = useState(product.variants[0])
  const [selectedVariantQuantity, setselectedVariantQuantity] = useState(1)
  const [activeFlag, setactiveFlag] = useState(false)
  let titleBorderColor = "loop"

  const handleQuantityChange = event => {
    setselectedVariantQuantity(event.target.value)
    productGtmEvent(
      product,
      selectedVariant,
      selectedVariantQuantity,
      intl.locale
    )
  }

  const handleAddtoCart = () => {
    addVariantToCart(
      selectedVariant.id,
      selectedVariantQuantity,
      null,
      checkout => {
        addToCartScript(
          product.id,
          product.title,
          selectedVariant,
          selectedVariantQuantity,
          checkout,
          intl.locale
        )
        navigateToCart(intl.locale)
      }
    )
  }

  const BottomBarActive = () => {
    function isOnScreen(elem) {
      // if the element doesn't exist, abort
      if (elem.length == 0) {
        return
      }
      var $window = $(window)
      var viewport_top = $window.scrollTop()
      var viewport_height = $window.height()
      var viewport_bottom = viewport_top + viewport_height
      var $elem = $(elem)
      var top = $elem.offset().top
      var height = $elem.height()
      var bottom = top + height

      return (
        (top >= viewport_top && top < viewport_bottom) ||
        (bottom > viewport_top && bottom <= viewport_bottom) ||
        (height > viewport_height &&
          top <= viewport_top &&
          bottom >= viewport_bottom)
      )
    }

    $(document).ready(function() {
      window.addEventListener("scroll", function(e) {
        if (!isOnScreen($("#add_to_cart")) && !isOnScreen($("#footer"))) {
          /* Pass element id/class you want to check */
          setactiveFlag(true)
        } else {
          setactiveFlag(false)
        }
      })
    })
  }

  const handleScrolling = () => {
    $("#sticy-button").click(function() {
      var w = window.outerWidth
      if (w <= 600) {
        $("html, body").animate(
          {
            scrollTop: $("#mobile_below_image").offset().top - 150,
          },
          1500
        )
      } else {
        $("html, body").animate(
          {
            scrollTop: $("body").offset().top,
          },
          1500
        )
      }
    })
  }

  useEffect(() => {
    BottomBarActive()
    handleScrolling()
    if (intl.locale === "de" || intl.locale === "es")
      navigate(`/${intl.locale}/shop/`)
    else {
      productGtmEvent(
        product,
        selectedVariant,
        selectedVariantQuantity,
        intl.locale
      )
    }
  }, [intl.locale])

  return (
    (intl.locale === "fr" || intl.locale === "en") &&
    data &&
    data.allDirectusLoopTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          jsonLd="loop"
          hreflangPathname={{
            fr: "shop/loop",
            en: "shop/loop",
            de: "shop/loop",
            es: "shop/loop",
          }}
        />

        <BottomStickyAddToCart
          title={data.allDirectusLoopTranslation.nodes[0].product_title}
          image={
            data.allDirectusLoopTranslation.nodes[0].product_title_logo_image
          }
          cart_text={
            data.allDirectusLoopTranslation.nodes[0].product_add_to_cart_button
          }
          regularPrice={Number(
            product.priceRangeV2.maxVariantPrice.amount
          ).toFixed(0)}
          handleAddTocart={selectedVariant ? handleAddtoCart : null}
          active={activeFlag}
          id="sticy-button"
          page="loop"
        />

        <PriceDetails
          details={data.allDirectusLoopTranslation.nodes[0].price_details}
          open={openModal}
          closeHandle={setopenModal}
        />

        {data.allDirectusLoopTranslation.nodes[0].product_title && (
          <ProductTitleWrapper>
            <ProductSingleTitle
              dangerouslySetInnerHTML={{
                __html: data.allDirectusLoopTranslation.nodes[0].product_title,
              }}
            />
          </ProductTitleWrapper>
        )}

        <ProductTopDesktopSection>
          <Container>
            <WaviiTopSection
              image={
                data.allDirectusLoopTranslation.nodes[0]
                  .product_title_logo_image
              }
              review_data={data.allDirectusReviewTranslation.nodes}
            />
          </Container>
        </ProductTopDesktopSection>

        <ProductInfoSection id="info-section">
          <Container>
            <ProductInfoBox>
              <ProductImageSection>
                {intl.locale === "fr" ? (
                  <ProductImageVideoSlider data={imageSliderloop} />
                ) : (
                  <ProductImageSlider data={imageSliderloopEn} />
                )}
                {/* <ProductImageSlider data={imageSliderloop} /> */}
              </ProductImageSection>

              <ProductMobileBelowImageSection id="mobile_below_image">
                <WaviiTopSection
                  image={
                    data.allDirectusLoopTranslation.nodes[0]
                      .product_title_logo_image
                  }
                  review_data={data.allDirectusReviewTranslation.nodes}
                  openModal={setopenModal}
                  price={Number(
                    product.priceRangeV2.maxVariantPrice.amount
                  ).toFixed(0)}
                />
              </ProductMobileBelowImageSection>

              <ProductRightInfoSection>
                <ProductSummaryTop>
                  <ProductMaterialInfo
                    data={
                      data.allDirectusLoopTranslation.nodes[0]
                        .product_material_info
                    }
                  />
                </ProductSummaryTop>
                <ProductSummaryTop>
                  <ProductInfo
                    dangerouslySetInnerHTML={{
                      __html:
                        data.allDirectusLoopTranslation.nodes[0].product_info,
                    }}
                  />
                </ProductSummaryTop>
                <ProductWaviiInfo
                  data={
                    data.allDirectusLoopTranslation.nodes[0].product_loop_info
                  }
                  page="loop"
                />
                <ProductInfoBottom>
                  <DesktopPrice style={{ maxWidth: 125 }}>
                    <ProductPriceWrapper>
                      <ProductPrice>
                        {process.env.GATSBY_CURRENCY_TYPE !== "eur"
                          ? process.env.GATSBY_CURRENCY
                          : ""}
                        {Number(
                          product.priceRangeV2.maxVariantPrice.amount
                        ).toFixed(0)}
                        {process.env.GATSBY_CURRENCY_TYPE === "eur"
                          ? process.env.GATSBY_CURRENCY
                          : ""}
                      </ProductPrice>

                      <ProductPlusDetail
                        onClick={() => setopenModal(!openModal)}
                      >
                        {intl.formatMessage({
                          id: "product_plus_details_text",
                        })}
                      </ProductPlusDetail>
                    </ProductPriceWrapper>
                  </DesktopPrice>
                  <ProductInfoBottomRight mobileDisplay={true}>
                    <ProductQuantity
                      handleQuantityChange={handleQuantityChange}
                      selectedVariantQuantity={selectedVariantQuantity}
                      mobileDisplay={true}
                    />
                    <div style={{ width: "100%" }}>
                      <ProductAddToCart
                        id="add_to_cart"
                        loading={storeContext.adding ? "adding" : ""}
                        onClick={handleAddtoCart}
                      >
                        {
                          data.allDirectusLoopTranslation.nodes[0]
                            .product_add_to_cart_button
                        }
                      </ProductAddToCart>
                      <ProductEstimateShippingBox>
                        <ProductEstimateShippingIcon
                          className="lazyload"
                          data-src="https://static.percko.com/uploads/f60c3d29-f9bf-4282-bedd-62a1af4b98a8.svg"
                        />
                        <ProductEstimateShippingText>
                          {cartEstimateDeliveryText(
                            "loop",
                            data.allDirectusCommonTranslation.nodes[0]
                              .months_text,
                            intl.locale
                          )}
                        </ProductEstimateShippingText>
                      </ProductEstimateShippingBox>
                    </div>
                  </ProductInfoBottomRight>
                </ProductInfoBottom>
                <ProductWaviiMobileBanner
                  data={
                    data.allDirectusLoopTranslation.nodes[0].product_banner_text
                  }
                />
              </ProductRightInfoSection>
            </ProductInfoBox>
          </Container>
        </ProductInfoSection>

        <ProductWaviiBanner
          data={data.allDirectusLoopTranslation.nodes[0].product_banner_text}
          page="loop"
          background="#142F49"
        />

        <ProductWhyChooseSection
          borderColor={titleBorderColor}
          titleBgColor="loop"
          data={data.allDirectusLoopTranslation.nodes[0].why_choose_slider}
        />
        <ProductTechnology
          borderColor={titleBorderColor}
          titleBgColor="loop"
          title={data.allDirectusLoopTranslation.nodes[0].loop_technology_title}
          subtitle={
            data.allDirectusLoopTranslation.nodes[0].loop_technology_subtitle
          }
          desktopImage={
            data.allDirectusLoopTranslation.nodes[0]
              .loop_technology_desktop_image
          }
          sliderImage={
            data.allDirectusLoopTranslation.nodes[0]
              .loop_technology_mobile_slider
          }
          mobileText={
            data.allDirectusLoopTranslation.nodes[0].loop_technology_text
          }
        />
        <ProductLoopMessage
          text={data.allDirectusLoopTranslation.nodes[0].loop_message_text}
          buttonText={
            data.allDirectusLoopTranslation.nodes[0].loop_message_button_text
          }
          desktopImage={
            data.allDirectusLoopTranslation.nodes[0].loop_message_desktop_image
          }
          mobileImage={
            data.allDirectusLoopTranslation.nodes[0].loop_message_mobile_image
          }
        />

        {data.allDirectusReviewTranslation.nodes && (
          <TrustPilotReviewSection
            data={data.allDirectusReviewTranslation.nodes}
            id={"review_section"}
          />
        )}

        {process.env &&
        data.allDirectusLoopTranslation.nodes[0].gbp_product_faq_section &&
        process.env.GATSBY_ACTIVE_ENV === "gbp" ? (
          <WaviiFaqSection
            borderColor={titleBorderColor}
            data={
              data.allDirectusLoopTranslation.nodes[0].gbp_product_faq_section
            }
          />
        ) : (
          <WaviiFaqSection
            borderColor={titleBorderColor}
            data={data.allDirectusLoopTranslation.nodes[0].product_faq_section}
          />
        )}

        {data.allDirectusHomeTranslation.nodes[0].customer_servcie_text && (
          <CustomerService
            text={
              data.allDirectusHomeTranslation.nodes[0].customer_servcie_text
            }
          />
        )}
        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}
        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusHomeTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        customer_servcie_text
      }
    }
    allDirectusLoopTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        product_title
        product_title_logo_image {
          data {
            url
          }
        }
        product_material_info
        product_info
        product_loop_info
        product_add_to_cart_button
        product_banner_text
        why_choose_slider {
          section_title
          mobile_section_title
          section_subtitle
          slider {
            image
            title
            text
            alt
          }
        }
        loop_technology_title
        loop_technology_subtitle
        loop_technology_desktop_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1080, quality: 100)
            }
            extension
          }
        }
        loop_technology_mobile_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 250, quality: 100)
            }
            extension
          }
        }
        loop_technology_mobile_slider {
          data
        }
        loop_technology_text {
          technology {
            title
            text
          }
        }
        loop_message_desktop_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1080, quality: 100)
            }
            extension
          }
        }
        loop_message_mobile_image {
          data {
            url
          }
          localFile {
            childImageSharp {
              gatsbyImageData(width: 490, quality: 100)
            }
            extension
          }
        }
        loop_message_text
        loop_message_button_text
        price_details
        product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              text
            }
          }
        }
        gbp_product_faq_section {
          title
          subtitle
          list {
            question
            answer {
              text
            }
          }
        }
        language
      }
    }
    allDirectusReviewTranslation(
      filter: { review: { product: { eq: "loop" } }, language: { eq: $lang } }
    ) {
      nodes {
        author_name
        review_text
        place_and_date
        review {
          stars
          name
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
        months_text {
          month
        }
      }
    }
    shopifyProduct(handle: { eq: "loop" }) {
      id: storefrontId
      title
      handle
      variants {
        id: storefrontId
        title
        image {
          id
        }
        sku
        price
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "loop" } } }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default withStoreContext(injectIntl(Loop))
